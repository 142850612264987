import { projectFirestore } from '../../../../../firebase/config';

const get_asortments = async() => {
    const docs = await projectFirestore.collection('Assortments').get()
    const res = docs.docs.map(doc=>({name:`(${doc.data().makat}) - ${doc.data().name}`,makat:doc.data().makat}))
    return res
}

const store_new_pdf_in_DB = async(data) => {
    const doc = projectFirestore.collection('Flayers').doc()
    await doc.set({
        ...data,
        uid:doc.id
    })
}

const store_edit_pdf_in_DB = async(data) => {
    await projectFirestore.collection('Flayers').doc(data.uid)
    .set(data,{merge:true})
}

const get_all_flayers_from_DB = async() => {
    const docs = await projectFirestore.collection('Flayers').get()
    const res = docs.docs.map(doc=>doc.data())
    return res
}

const delete_flayer_from_DB = async(flayer_uid) => {
    await projectFirestore.collection('Flayers').doc(flayer_uid).delete()
}

const get_all_departments = async() => {
    const docs = await projectFirestore.collection('Departments').get()
    const obj = {}
    const dep_names = []
    docs.docs.forEach(doc=>{
        obj[doc.data().name] = doc.data().subs
        dep_names.push(doc.data().name)
    })
    
    return {
        dep_names,
        departments:obj
    }
}


export{
    get_asortments,
    store_new_pdf_in_DB,
    get_all_flayers_from_DB,
    delete_flayer_from_DB,
    store_edit_pdf_in_DB,
    get_all_departments
}